@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Sora", sans-serif;
  color: #444444;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #b4e7fe;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif, monospace;
}

/*Scrollbar*/
/* width */
::-webkit-scrollbar {
  display: block;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2e2e2e00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 185, 185);
}

/* Navbar */

@media (min-width: 992px) {
  #navbarText {
    background-color: rgb(0, 0, 0, 0, 0);
  }
}

@media (max-width: 991px) {
  #navbarText {
    background-color: black;
  }
  .navbar img {
    width: "50px";
    height: "auto";
  }
}

.navbar {
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.close-navbar-toggler.collapsed {
  z-index: -1;
}

#logoimg {
  height: auto;
  width: 33vh;
}

.nav-item {
  margin-left: 20px;
  margin-top: 7px;
}

@media (max-width: 720px) {
  #logoimg {
    height: auto;
    width: 30vh;
  }

  #kkk {
    margin-top: 25px;
  }
}

#morebtn {
  color: white;
  font-size: 12px;
  padding: 15px;
  border-radius: 4px;
  font-weight: bold;
}

#morebtn:hover {
  background-color: #9f9f9f;
}

/* Coverpage start*/

#cover {
  background: linear-gradient(90deg, #000000 0%, #252525 100%);
}

#coverimage {
  border-radius: 8px;
}

@media (min-width: 1479px) {
  #cover {
    height: 75vh;
    padding-top: 25vh;
  }

  #covertext {
    width: 49vh;
    margin-top: -50px;
  }

  #cover h1 {
    font-size: 45px;
  }

  #cover p {
    margin-top: 20px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1479px) {
  #cover {
    height: 62vh;
    padding-top: 22vh;
  }

  #covertext {
    width: 40vh;
    margin-top: -50px;
  }

  #cover h1 {
    font-size: 40px;
  }

  #cover p {
    margin-top: 20px;
    font-size: 18px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  #cover {
    height: 54vh;
    padding-top: 22vh;
  }

  #covertext {
    width: 34vh;
    margin-top: -50px;
  }

  #cover h1 {
    font-size: 30px;
  }

  #cover p {
    margin-top: 20px;
    font-size: 14px;
  }
}

@media (min-width: 600px) and (max-width: 991px) {
  #cover {
    height: 100%;
    padding-top: 22vh;
  }

  #covertext {
    width: 60%;
    margin-top: -50px;
  }

  #cover h1 {
    margin-top: -15px;
    font-size: 35px;
  }

  #cover p {
    margin-top: 20px;
    font-size: 13px;
  }

  #coverimage {
    margin-top: 20px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  #cover {
    height: 100%;
    padding-top: 22vh;
  }

  #covertext {
    width: 60%;
    margin-top: -50px;
  }

  #cover h1 {
    margin-top: -15px;
    font-size: 35px;
  }

  #cover p {
    margin-top: 20px;
    font-size: 13px;
  }

  #coverimage {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  #cover {
    height: 70vh;
    padding-top: 22vh;
  }

  #coverimage {
    margin-top: 25px;
  }

  #covertext {
    width: 100%;
    margin-top: -50px;
  }

  #cover h1 {
    margin-top: -15px;
    font-size: 21px;
  }

  #cover p {
    margin-top: 10px;
    font-size: 13px;
  }
}

/* Coverpage end*/

/* team start*/

@media (min-width: 1322px) {
  #picteam {
    height: 30vh;
    width: 30vh;
    object-fit: cover;
  }
}

@media (min-width: 1180px) and (max-width: 1322px) {
  #picteam {
    height: 25vh;
    width: 30vh;
    object-fit: cover;
  }

  #team h6 {
    font-size: 12px;
  }

  #team p {
    font-size: 12px;
  }
}

@media (min-width: 991px) and (max-width: 1180px) {
  #picteam {
    height: 25vh;
    width: 30vh;
    object-fit: cover;
  }

  #team h6 {
    font-size: 12px;
  }

  #team p {
    font-size: 12px;
  }
}

@media (min-width: 550px) and (max-width: 991px) {
  #picteam {
    height: 40vh;
    width: 35vh;
    object-fit: cover;
  }
}

@media (max-width: 550px) {
  #picteam {
    height: 55vh;
    width: 100%;
    object-fit: cover;
  }
}

/* Team end*/

@media (max-width: 550px) {
  #images {
    height: 28vh;
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 551px) {
  #images {
    height: 29.5vh;
    width: 100%;
    object-fit: cover;
  }
}

table {
  font-size: 13px;
  text-align: left;
}

td,
th {
  border: 1px solid #d4d4d4;
}

tr:nth-child(even) {
  background-color: #9f3a3810;
}

#hero {
  width: 100%;
  height: 85vh;
  margin-top: 10vh;
  background: url("./Components/assets/image/wallpaper.jpg") top center;

  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.555);

  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 100px;
  text-align: center;
}

#hero h1 {
  margin: 20vh 0 0 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.615);
  height: 100%;
  border-radius: 8px;
  background-color: #8b8b8b3d;
  text-align: center;
}

#hero .icon-box:hover {
  background-color: #1d1d1da2;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #fff;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

/* #hero .icon-box:hover {
     border-color: #C61E5E;
   }  */

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    margin-top: 10vh;
    height: 60vh;
  }
  #hero h1 {
    font-size: 29px;
    line-height: 36px;
    margin-top: 70px;
  }
  #hero h2 {
    font-size: 16px;
    line-height: 24px;
  }
}

.collab {
  padding-top: 20px;
}

@media (max-width: 405px) {
  .collab img {
    min-width: 10vh;
  }
}
